<template>
  <div class="about">
    <div class="aboutBanner">
      <img src="@/assets/img/AboutBingmo/banner_bmou2.jpg" />
    </div>
    <div class="aboutInt_list">
      <div>
        <div class="aboutInt_listInfo_left">
          <div>
            <div>锁定食物质量</div>
            <div></div>
          </div>
          <div>冰箱食物进出时间管理</div>
          <div>
            提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能
          </div>
        </div>
        <div class="aboutInt_listImg_left">
          <img src="@/assets/img/AboutBingmo/pic_bxiangs.png" />
        </div>
      </div>
      <div>
        <div class="aboutInt_listImg_right">
          <img src="@/assets/img/AboutBingmo/pic_scheng.png" />
        </div>
        <div class="aboutInt_listInfo_right">
          <div>
            <div>便捷生鲜购物</div>
            <div></div>
          </div>
          <div>食物推荐及小区商铺购物</div>
          <div>
            根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据APP上显示的食材存量及时补充食物，享受购物便捷的服务
          </div>
        </div>
      </div>
      <div>
        <div class="aboutInt_listInfo_left">
          <div>
            <div>分享烹饪心得</div>
            <div></div>
          </div>
          <div>参考食物制作、分享烹饪心得</div>
          <div>
            查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人
          </div>
        </div>
        <div class="aboutInt_listImg_left">
          <img src="@/assets/img/AboutBingmo/pic_cpu.png" />
        </div>
      </div>
      <div>
        <div class="aboutInt_listImg_right">
          <img src="@/assets/img/AboutBingmo/pic_wde.png" />
        </div>
        <div class="aboutInt_listInfo_right">
          <div>
            <div>设置简单易用</div>
            <div></div>
          </div>
          <div>冰箱管理、成员设置简单易用</div>
          <div>
            轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全
          </div>
        </div>
      </div>
    </div>
    <div class="aboutFeatures">
      <div class="aboutFeatures_img">
        <img src="@/assets/img/AboutBingmo/bg_fqgneng2.jpg" />
      </div>
      <div class="aboutFeatures_info">
        <div class="aboutFeatures_infoTop">
          <div class="aboutFeatures_infoTopLeft">
            <div>善用您的冰箱分区功能</div>
            <div>
              冰眸的食物管理功能页面，除了提供当前天气状况，还可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理。
              目前市场主流的冰箱分为三门、对开双门、四门冰箱，根据不同冰箱结构及存储区域的需求，用户可以对冰箱的各个存储区域进行自定义设定并命名，进行个性化食物管理。
            </div>
          </div>
          <div class="aboutFeatures_infoTopRight">
            <img src="@/assets/img/AboutBingmo/pic_bxiang.png" />
          </div>
        </div>
        <div class="aboutFeatures_infoMain">
          <div class="aboutFeatures_infoMainItem">
            <div>1</div>
            <div>
              <div>存储区域管理</div>
              <div>
                对冰箱的各功能存储区域进行个性化定制管理，用户可以根据自己冰箱的结构进行分类管理，如柜门区、冷藏区、生鲜区、冰冻区等
              </div>
            </div>
          </div>
          <div class="aboutFeatures_infoMainItem">
            <div>2</div>
            <div>
              <div>食物分类管理</div>
              <div>
                用户可根据食物的特征，自主地对食物的品类进行分类管理，如水果类，海产类，蔬果类等，让食物的管理更加清晰明了
              </div>
            </div>
          </div>
          <div class="aboutFeatures_infoMainItem">
            <div>3</div>
            <div>
              <div>预警食物信息提醒</div>
              <div>
                可定义食物的存放时间，当食物存放在冰箱的时间即将到达设置的时间点，冰眸将发送预警信息提醒用户及时食用
              </div>
            </div>
          </div>
          <div class="aboutFeatures_infoMainItem">
            <div>4</div>
            <div>
              <div>逾期食物信息警示</div>
              <div>
                可定义食物的存放时间，如存放在冰箱的时间超过预设的时间，冰眸将发送该食物的逾期信息通知用户进行处理
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutProblem">
      <div>常见问题</div>
      <div>
        <div
          v-for="(item, index) in problemList"
          :key="index"
          class="aboutProblem_item">
          <div style="margin-top: 8px;">
            <div class="aboutProblem_icon"></div>
          </div>
          <div class="aboutProblem_info">
            <div>{{ item.title }}</div>
            <div>{{ item.info }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      problemList: [
        {
          title: "有免费使用吗?",
          info:
            "我们前期将在广州特定小区开展活动，现场参与并填写相关资料将免费获得冰眸产品，该指定小区获得产品将享受终身免费。",
        },
        {
          title: "在哪里可以买到冰眸",
          info:
            "冰眸在进行体验活动后，于淘宝、京东等平台上开设店铺进行冰眸产品直销，具体开放时间请关注官网或公众号",
        },
        {
          title: "一个产品能否多人试用？",
          info:
            "在冰眸的设计中，进行了多台冰箱或多个家庭成员的操作体系，主控账户可以分享某个绑定了冰箱给家庭成员进行关联使用",
        },
        {
          title: "非特定小区能否免费？",
          info:
            "如对冰眸产品深感兴趣并愿意为产品提供使用建议或反馈产品相关问题，可在官网下方发送个人信息，我们将根据需求随机挑选体验用户进行赠送",
        },
        {
          title: "如果无法使用怎么办？",
          info:
            "请联系公司客户热线020-32309269或发送邮件至service@vlimsoft.com 我们的工作人员将在24小时内为您进行处理或更换",
        },
        {
          title: "是否可以在冰眸上买食物？",
          info:
            "视用户的承载量而定，如果用户位置2公里范围内超过200个冰眸家庭，那么将在冰眸app上显示附近入驻的食物(如生鲜、水果等)店铺",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* 关于头图 */
.aboutBanner {
  width: 100%;
  height: 580px;
}
.aboutBanner img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 介绍 */
.aboutInt {
  width: 1440px;
  height: 874px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.aboutInt > div {
  width: 50%;
}
.aboutInt_left {
  position: relative;
}
.aboutInt_left > div:nth-of-type(1) {
  width: 540px;
  height: 390px;
  background: #47b0b4;
  opacity: 0.2;
  position: absolute;
  bottom: 100px;
  right: 100px;
}
.aboutInt_left > div:nth-of-type(2) {
  width: 280px;
  height: 564px;
  position: absolute;
  bottom: 164px;
  right: 152px;
}
.aboutInt_left > div:nth-of-type(2) img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.aboutInt_right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.aboutInt_right > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  width: 480px;
}
.aboutInt_right > div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #99ccce;
  line-height: 42px;
  width: 480px;
  margin-top: 56px;
  text-indent: 2em;
}
/* 介绍列表 */
.aboutInt_list {
  width: 1440px;
  margin: 160px auto;
  padding: 0 114px;
  box-sizing: border-box;
}
.aboutInt_list > div {
  margin-top: 20px;
  width: 100%;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.aboutInt_listInfo_left {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.aboutInt_listInfo_left > div:nth-of-type(1) {
  position: relative;
}
.aboutInt_listInfo_left > div:nth-of-type(1) > div:nth-of-type(1) {
  position: relative;
  z-index: 1;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
}
.aboutInt_listInfo_left > div:nth-of-type(1) > div:nth-of-type(2) {
  width: 200px;
  height: 12px;
  background: #47b0b4;
  position: relative;
  top: -12px;
}
.aboutInt_listInfo_left > div:nth-of-type(2) {
  margin: 0 0 18px;
  font-size: 24px;
  /* font-weight: 400; */
  color: #666666;
  line-height: 36px;
}
.aboutInt_listInfo_left > div:nth-of-type(3) {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  width: 384px;
  height: 76px;
}
.aboutInt_listImg_left {
  width: 50%;
  height: 100%;
}
.aboutInt_listImg_left img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: contain;
}

.aboutInt_listInfo_right {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.aboutInt_listInfo_right > div:nth-of-type(1) {
  position: relative;
}
.aboutInt_listInfo_right > div:nth-of-type(1) > div:nth-of-type(1) {
  position: relative;
  z-index: 1;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
}
.aboutInt_listInfo_right > div:nth-of-type(1) > div:nth-of-type(2) {
  width: 200px;
  height: 12px;
  background: #47b0b4;
  position: relative;
  top: -12px;
}
.aboutInt_listInfo_right > div:nth-of-type(2) {
  margin: 0 0 18px;
  font-size: 24px;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
}
.aboutInt_listInfo_right > div:nth-of-type(3) {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  width: 384px;
  height: 76px;
}
.aboutInt_listImg_right {
  width: 50%;
  height: 100%;
}
.aboutInt_listImg_right img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: contain;
}

/* 功能 */
.aboutFeatures {
  position: relative;
  height: 1088px;
}
.aboutFeatures_img {
  overflow: hidden;
  width: 100%;
  height: 1088px;
}
.aboutFeatures_img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.aboutFeatures_info {
  width: 1440px;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}
.aboutFeatures_infoTop {
  padding: 0 168px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aboutFeatures_infoTopLeft {
  width: calc(100% - 360px);
  margin-right: 100px;
}
.aboutFeatures_infoTopLeft > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
}
.aboutFeatures_infoTopLeft > div:nth-of-type(2) {
  font-size: 24px;
  color: #cccccc;
  margin-top: 40px;
}
.aboutFeatures_infoTopRight {
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutFeatures_infoTopRight img {
  width: 232px;
  height: 496px;
  display: flex;
  object-fit: cover;
}
.aboutFeatures_infoMain {
  width: 1440px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aboutFeatures_infoMain > div {
  width: 50%;
}
.aboutFeatures_infoMainItem {
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  box-sizing: border-box;
}
.aboutFeatures_infoMainItem > div:nth-of-type(1) {
  font-size: 60px;
  font-weight: 500;
  line-height: 64px;
  margin-right: 6px;
}
.aboutFeatures_infoMainItem > div:nth-of-type(2) > div:nth-of-type(1) {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}
.aboutFeatures_infoMainItem > div:nth-of-type(2) > div:nth-of-type(2) {
  font-weight: 400;
  color: #cccccc;
  font-size: 16px;
  line-height: 24px;
}

/* 常见问题 */
.aboutProblem {
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 120px auto 180px;
}
.aboutProblem > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #000;
}
.aboutProblem > div:nth-of-type(2) {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aboutProblem_item {
  width: calc(50% - 115px);
  display: flex;
  justify-content: flex-start;
  margin-top: 80px;
  box-sizing: border-box; 
}
.aboutProblem_icon {
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  transform: rotate(45deg);
  box-sizing: border-box;
  margin: 3px;
}
.aboutProblem_info {
  margin-left: 8px;
}
.aboutProblem_info > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
}
.aboutProblem_info > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-top: 10px;
}
</style>
